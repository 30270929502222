import decode from 'jwt-decode';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginDetails } from '../interfaces/LoginDetails';
import { ToastsService } from './../../../shared/services/toasts.service';
import { SpinnerService } from './../../../shared/services/spinner.service';
import { ApiService } from '../../dashboard/services/api.service';
import { error } from 'console';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfirmationService } from 'primeng/api';
const API_URL = '/api';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private router: Router,
        private jwtHelper: JwtHelperService,
        private spinnerService: SpinnerService,
        private http: HttpClient,
        private toastsService: ToastsService,
        private confirmationService: ConfirmationService
    ) {}

    // check jwt token is expired or not
    public isAuthenticated(): boolean {
        try {
            const token = localStorage.getItem('idToken');
            const userId = localStorage.getItem('userId');
            const tokenPayload: any = decode(token);
            return !this.jwtHelper.isTokenExpired(token);
            // &&
            // tokenPayload.user_id === userId
        } catch (error) {
            this.toastsService.error(error as string);
            // console.error(error);
            this.logout();
            return false;
        }
    }

    logout() {
        this.confirmationService.confirm({
            message: 'Are you sure you want to log out ?',
            header: 'Log Out',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Yes',
            rejectLabel: 'No',
            acceptButtonStyleClass: 'p-button-danger',
            rejectButtonStyleClass: 'p-button-secondary',
            accept: () => {
                // Action to perform on accept
                this.spinnerService.startLoading();
                setTimeout(() => {
                    this.spinnerService.stopLoading();
                    localStorage.removeItem('idToken');
                    this.router.navigate(['auth/login'], { replaceUrl: true });
                }, 1000);
            },
            reject: () => {
                // Action to perform on reject
            },
        });
    }

    adminLogin(logindata: LoginDetails): Observable<any[]> {
        this.spinnerService.startLoading();
        return this.http.post<any>(`${API_URL}/login`, logindata).pipe(
            tap((response: any) => {
                if (response.success) {
                    this.toastsService.success(response.message);
                    setTimeout(() => {
                        localStorage.setItem('idToken', response.data);
                        this.spinnerService.stopLoading();
                        this.router.navigate(['/script'], { replaceUrl: true });
                    }, 1000);
                } else {
                    this.toastsService.error(response.message);
                    this.spinnerService.stopLoading();
                }
            }),
            catchError((error: any) => {
                // Handle error response here
                setTimeout(() => {
                    this.spinnerService.stopLoading();
                }, 1000);
                return throwError(() => {
                    return this.toastsService.error(error.message);
                });
            })
        );
    }
}
