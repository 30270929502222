<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="/script">
        <img src="assets/layout/images/{{coreService.config.colorScheme === 'light' ? 'main-icon' : 'main-icon'}}.png" alt="logo">
        <span>VIDEO DOWNLOAD</span>
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="coreService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button m-2" (click)="coreService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>


    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': coreService.state.profileSidebarVisible}">
        <button (click)="coreService.toggleTheme()" class="p-link layout-topbar-button" [pTooltip]="coreService.isDarkMode ? 'Light mode' : 'Dark mode' " tooltipPosition="bottom">
            <i [class]="coreService.isDarkMode ? 'pi pi-sun' : 'pi pi-moon'"></i>
            <span>{{coreService.isDarkMode ? 'Light mode' : 'Dark mode'}}</span>
        </button>
        <!-- <button class="p-link layout-topbar-button">
            <i class="pi pi-user"></i>
            <span>Profile</span>
        </button> -->
        <button class="p-link layout-topbar-button" (click)="authService.logout()" pTooltip="Log out" tooltipPosition="bottom">
            <i class="pi pi-sign-out"></i>
            <span>Log out</span>
        </button>
    </div>
     <!-- confirmation dislog for delete system setting record -->
     <div class="record-delet-modal">
        <p-confirmDialog [style]="{width: '500px'}"></p-confirmDialog>
    </div>
</div>