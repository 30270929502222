import { Component, ElementRef, OnInit } from '@angular/core';
import { CoreService } from '../../../application/core/services/core.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './app.sidebar.component.html',
})
export class AppSidebarComponent implements OnInit {
    model: any[] = [];

    constructor(public el: ElementRef, public coreService: CoreService) {}

    ngOnInit() {
        this.model = [
            {
                items: [
                    // {
                    //     label: 'Dashboard',
                    //     icon: 'pi pi-fw pi-home',
                    //     routerLink: ['/dashboard']
                    // },
                    {
                        label: 'Script',
                        icon: 'pi pi-fw pi-book',
                        routerLink: ['/script'],
                    },
                    {
                        label: 'System Setting',
                        icon: 'pi pi-fw pi-cog',
                        routerLink: ['/system-setting'],
                    },
                    {
                        label: 'User',
                        icon: 'pi pi-fw pi-user',
                        routerLink: ['/user'],
                        // items: [
                        //     {
                        //         label: 'User List',
                        //         icon: 'pi pi-fw pi-id-card',
                        //     },
                        //     {
                        //         label: 'Add User',
                        //         icon: 'pi pi-fw pi-plus',
                        //         routerLink: ['/user']
                        //     }
                        // ]
                    },
                    {
                        label: 'Report',
                        icon: 'pi pi-fw pi-id-card',
                        routerLink: ['/report'],
                    },
                    {
                        label: 'Interstitial Ad. Setting',
                        icon: 'pi pi-fw pi-mobile',
                        routerLink: ['/iterstitial-ad-setting'],
                    },
                    {
                        label: 'Support Request',
                        icon: 'pi pi-fw pi-check-square',
                        routerLink: ['/support-request'],
                    },
                    {
                        label: 'Interstitial Ad. Report',
                        icon: 'pi pi-fw pi-megaphone',
                        routerLink: ['/iterstitial-ad-report'],
                    },
                    {
                        label: 'User Entry Log',
                        icon: 'pi pi-fw pi-clock',
                        routerLink: ['/user-entry-log'],
                    },
                    {
                        label: 'Notification',
                        icon: 'pi pi-fw pi-bell',
                        routerLink: ['/push-notification'],
                    },
                    {
                        label: 'App Version',
                        icon: 'pi pi-fw pi-sliders-v',
                        routerLink: ['/app-version'],
                    },
                    {
                        label: 'Home Screen Data',
                        icon: 'pi pi-fw pi-desktop',
                        routerLink: ['/home-screen'],
                    },
                    {
                        label: 'Search History',
                        icon: 'pi pi-fw pi-history',
                        routerLink: ['/search-history'],
                    },
                    {
                        label:'Get In Touch',
                        icon:'pi pi-fw pi-envelope',
                        routerLink:['/get-in-touch']
                    },
                    {
                        label:'App Ads.',
                        icon:'pi pi-fw pi-file-export',
                        routerLink:['/app-ads']
                    }
                ],
            },
            // {
            //     label: 'Pages',
            //     icon: 'pi pi-fw pi-briefcase',
            //     items: [
            //         {
            //             label: 'Error',
            //             icon: 'pi pi-fw pi-times-circle',
            //             routerLink: ['/error']
            //           },
            //           {
            //             label: 'Access Denied',
            //             icon: 'pi pi-fw pi-lock',
            //             routerLink: ['/unauthorized']
            //           },
            //           {
            //             label: 'Not Found',
            //             icon: 'pi pi-fw pi-exclamation-circle',
            //             routerLink: ['/notfound']
            //           },
            //     ]
            // }
        ];
    }
}
